<template>
  <div>
    <div class="notFound">
      <h4 class="text-dark font-weight-bolder">Page not found!</h4>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    setTimeout(() => {
      this.$router.push({ name: "login" });
    }, 3000);
  },
};
</script>

<style >
.notFound {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
